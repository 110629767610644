<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
    @keydown.esc="goToProductsPage"
  >
    <ProductForm
      :product="editedProduct"
      :errors="productValidationErrors"
      :disabled="$store.getters.loading[`put:api/products/${editedProduct.id}`]"
      @clear:errors="clearProductValidationErrors"
      @cancel="goToProductsPage"
      @save="onSave"
    />
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import store from '@/store';
import ProductForm from '@/components/ProductForm';

export default {
  name: 'EditProduct',

  components: { ProductForm },

  computed: mapState('products', [
    'editedProduct',
    'productValidationErrors',
    'productFilterParams',
  ]),

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('products/editProduct', +to.params.id)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'products', query: store.state.products.productFilterParams });
      });
  },

  methods: {
    ...mapActions('products', ['updateProduct', 'clearProductValidationErrors']),

    onSave(product) {
      this.updateProduct(product).then(() => {
        this.goToProductsPage();
      });
    },

    goToProductsPage() {
      this.$router.push({ name: 'products', query: this.productFilterParams });
    },
  },
};
</script>

<style scoped></style>
